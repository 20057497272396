















































































































import {Component, Vue} from "vue-property-decorator";
import {
  TrainMethod,
  AttachmentHostType, LecturerDto, TrainDto, StarRatingDto, ServiceDto
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "ServiceDetail",
  components: {
    AttachmentsView,
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Deleted":
          return "已删除";
      }
    },
  },
})
export default class ServiceDetail extends Vue {
  dataId?: number;
  detail: ServiceDto = {
    serviceObjectType: {
      displayName: undefined
    },
    type: {
      displayName: undefined
    },
    leaderUser: {
      surname: undefined
    },
    serviceOrganization: {
      name: undefined
    },
    province: {
      provinceName: undefined
    },
    city: {
      cityName: undefined
    },
    area: {
      areaName: undefined
    }
  };
  // baseURL: any;
  loading = true;
  // attachments: any = []
  volunteerLevelList: any = [];

  async created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await this.fetchDetail();
      // await this.loadAttachments();
      await this.getVolunteerLevelList();
      this.loading = false;
    }
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  // 获取详情
  async fetchDetail() {
    await api.serviceMaintainService.get({id: this.dataId}).then((res) => {

      this.detail = {...res};
    });
  }

  async getVolunteerLevelList() {
    await api.dataDictionary.getDataDictionaryListByKey({
      key: 'VolunteerLevel'
    }).then((res) => {
      this.volunteerLevelList = res.items!;
    })
  }

  getVolunteerLevel(value: any) {
    for (let i = 0; i < this.volunteerLevelList.length; i++) {
      if (this.volunteerLevelList[i].displayOrder == value) {
        return this.volunteerLevelList[i].displayName
      }
    }
  }

  getCount() {
    if (this.detail.serviceObjectIds) {
      let serviceObjectList = this.detail.serviceObjectIds.split(',')
      return serviceObjectList.length
    }
  }

  async loadAttachments() {
    this.loading = false;
    await api.attachmentService
      .getAttachments({
        hostId: this.dataId!.toString(),
        hostType: AttachmentHostType.StarRating
      })
      .then((res) => {
        this.loading = true;
        this.attachments = res;
      });
    console.log(this.attachments);
  }

  //下载附件
  handleDownload(item: any) {
    // api.attachment.download(item.id);
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  isImage(item: any) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
